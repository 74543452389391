import { useTranslation } from "react-i18next";
import ReportCard from "./ReportCard";



function Reports() {
    
    const [t] = useTranslation();

    return (
        <div>



<section className="main-section">
	<div className="container">
		
		<div className="article">
			<h2 dangerouslySetInnerHTML={{__html: t('ReportsLabel')}}></h2>
			<p dangerouslySetInnerHTML={{__html: t('ReportsDescription')}}></p>
		</div>


		<div className="row cardbox-row">
            <ReportCard readmore={true} link="stuff_40" src="../images/uploads/stuff_40.jpg" title={t('reportcard_title_stuff_40')} description={t('reportcard_description_stuff_40')} />
            <ReportCard readmore={true} link="stuff_39" src="../images/uploads/stuff_39.jpg" title={t('reportcard_title_stuff_39')} description={t('reportcard_description_stuff_39')} />
            <ReportCard readmore={true} link="stuff_38" src="../images/uploads/stuff_38.jpg" title={t('reportcard_title_stuff_38')} description={t('reportcard_description_stuff_38')} />
            <ReportCard readmore={true} link="stuff_37" src="../images/uploads/stuff_37.jpg" title={t('reportcard_title_stuff_37')} description={t('reportcard_description_stuff_37')} />
            <ReportCard readmore={true} link="stuff_36" src="../images/uploads/stuff_36.jpg" title={t('reportcard_title_stuff_36')} description={t('reportcard_description_stuff_36')} />

            <ReportCard readmore={true} link="stuff_35" src="../images/uploads/stuff_35.jpg" title={t('reportcard_title_stuff_35')} description={t('reportcard_description_stuff_35')} />
            <ReportCard readmore={true} link="stuff_34" src="../images/uploads/stuff_34.jpg" title={t('reportcard_title_stuff_34')} description={t('reportcard_description_stuff_34')} />
            <ReportCard readmore={true} link="stuff_33" src="../images/uploads/stuff_33.jpg" title={t('reportcard_title_stuff_33')} description={t('reportcard_description_stuff_33')} />

            <ReportCard readmore={true} link="car_buy5_0" src="../images/uploads/car_buy5_0.jpg" title={t('reportcard_title_car_buy5_0')} description={t('reportcard_description_car_buy5_0')} />
            
            <ReportCard readmore={true} link="stuff_32" src="../images/uploads/stuff_32.jpg" title={t('reportcard_title_stuff_32')} description={t('reportcard_description_stuff_32')} />
            <ReportCard readmore={true} link="stuff_31" src="../images/uploads/stuff_31.jpg" title={t('reportcard_title_stuff_31')} description={t('reportcard_description_stuff_31')} />
            <ReportCard readmore={true} link="stuff_30" src="../images/uploads/stuff_30.jpg" title={t('reportcard_title_stuff_30')} description={t('reportcard_description_stuff_30')} />
            <ReportCard readmore={true} link="stuff_29" src="../images/uploads/stuff_29.jpg" title={t('reportcard_title_stuff_29')} description={t('reportcard_description_stuff_29')} />
            <ReportCard readmore={true} link="stuff_28" src="../images/uploads/stuff_28.jpg" title={t('reportcard_title_stuff_28')} description={t('reportcard_description_stuff_28')} />
			
            <ReportCard readmore={true} link="stuff_27" src="../images/uploads/stuff_27.jpg" title={t('reportcard_title_stuff_27')} description={t('reportcard_description_stuff_27')} />
            <ReportCard readmore={true} link="stuff_26" src="../images/uploads/stuff_26.jpg" title={t('reportcard_title_stuff_26')} description={t('reportcard_description_stuff_26')} />
            <ReportCard readmore={true} link="stuff_25" src="../images/uploads/stuff_25.jpg" title={t('reportcard_title_stuff_25')} description={t('reportcard_description_stuff_25')} />
            <ReportCard readmore={true} link="stuff_24" src="../images/uploads/stuff_24.jpg" title={t('reportcard_title_stuff_24')} description={t('reportcard_description_stuff_24')} />
            <ReportCard readmore={true} link="car_repair5" src="../images/uploads/car_repair5.jpg" title={t('reportcard_title_car_repair5')} description={t('reportcard_description_car_repair5')} />
            <ReportCard readmore={true} link="car_buy4_0" src="../images/uploads/car_buy4_0.jpg" title={t('reportcard_title_car_buy4_0')} description={t('reportcard_description_car_buy4_0')} />
            <ReportCard readmore={true} link="car_repair4" src="../images/uploads/car_repair4.jpg" title={t('reportcard_title_car_repair4')} description={t('reportcard_description_car_repair4')} />
            <ReportCard readmore={true} link="stuff_23" src="../images/uploads/stuff_23.jpg" title={t('reportcard_title_stuff_23')} description={t('reportcard_description_stuff_23')} />
            <ReportCard readmore={true} link="stuff_22" src="../images/uploads/stuff_22.jpg" title={t('reportcard_title_stuff_22')} description={t('reportcard_description_stuff_22')} />
            <ReportCard readmore={true} link="stuff_21" src="../images/uploads/stuff_21.jpg" title={t('reportcard_title_stuff_21')} description={t('reportcard_description_stuff_21')} />
            <ReportCard readmore={true} link="stuff_20" src="../images/uploads/stuff_20.jpg" title={t('reportcard_title_stuff_20')} description={t('reportcard_description_stuff_20')} />
            <ReportCard readmore={true} link="stuff_19" src="../images/uploads/stuff_19.jpg" title={t('reportcard_title_stuff_19')} description={t('reportcard_description_stuff_19')} />
            <ReportCard readmore={true} link="stuff_18" src="../images/uploads/stuff_18.jpg" title={t('reportcard_title_stuff_18')} description={t('reportcard_description_stuff_18')} />
            <ReportCard readmore={true} link="stuff_17" src="../images/uploads/stuff_17.jpg" title={t('reportcard_title_stuff_17')} description={t('reportcard_description_stuff_17')} />
            <ReportCard readmore={true} link="stuff_16" src="../images/uploads/stuff_16.jpg" title={t('reportcard_title_stuff_16')} description={t('reportcard_description_stuff_16')} />
            <ReportCard readmore={true} link="stuff_15" src="../images/uploads/stuff_15.jpg" title={t('reportcard_title_stuff_15')} description={t('reportcard_description_stuff_15')} />
            <ReportCard readmore={true} link="stuff_14" src="../images/uploads/stuff_14.jpg" title={t('reportcard_title_stuff_14')} description={t('reportcard_description_stuff_14')} />
            <ReportCard readmore={true} link="stuff_13" src="../images/uploads/stuff_13.jpg" title={t('reportcard_title_stuff_13')} description={t('reportcard_description_stuff_13')} />
            <ReportCard readmore={true} link="car_repair3" src="../images/uploads/car_repair3.jpg" title={t('reportcard_title_car_repair3')} description={t('reportcard_description_car_repair3')} />
            <ReportCard readmore={true} link="stuff_12" src="../images/uploads/stuff_12.jpg" title={t('reportcard_title_stuff_12')} description={t('reportcard_description_stuff_12')} />
            <ReportCard readmore={true} link="stuff_11" src="../images/uploads/stuff_11.jpg" title={t('reportcard_title_stuff_11')} description={t('reportcard_description_stuff_11')} />
            <ReportCard readmore={true} link="stuff_10_1" src="../images/uploads/stuff_10_1.jpg" title={t('reportcard_title_stuff_10_1')} description={t('reportcard_description_stuff_10_1')} />
            <ReportCard readmore={true} link="stuff_9_2" src="../images/uploads/stuff_9_2.jpg" title={t('reportcard_title_stuff_9_2')} description={t('reportcard_description_stuff_9_2')} />
            <ReportCard readmore={false} src="../images/uploads/stuff_8.jpg" title={t('reportcard_title_stuff_8')} description={t('reportcard_description_stuff_8')} />
            <ReportCard readmore={false} src="../images/uploads/stuff_7.jpg" title={t('reportcard_title_stuff_7')} description={t('reportcard_description_stuff_7')} />
            <ReportCard readmore={false} src="../images/uploads/stuff_6.jpg" title={t('reportcard_title_stuff_6')} description={t('reportcard_description_stuff_6')} />
            <ReportCard readmore={true} link="stuff_5_1" src="../images/uploads/stuff_5_1.jpg" title={t('reportcard_title_stuff_5_1')} description={t('reportcard_description_stuff_5_1')} />
            <ReportCard readmore={false} src="../images/uploads/stuff_4.jpg" title={t('reportcard_title_stuff_4')} description={t('reportcard_description_stuff_4')} />
            <ReportCard readmore={true} link="boots_1" src="../images/uploads/boots.jpg" title={t('reportcard_title_boots_1')} description={t('reportcard_description_stuff_1')} />
            <ReportCard readmore={true} link="car_repair2_1" src="../images/uploads/car_repair2_1.jpg" title={t('reportcard_title_car_repair2_1')} description={t('reportcard_description_car_repair2_1')} />
            <ReportCard readmore={false} src="../images/uploads/stuff_3.jpg" title={t('reportcard_title_stuff_3')} description={t('reportcard_description_stuff_3')} />
            <ReportCard readmore={true} link="car_buy2_0" src="../images/uploads/car_buy2_0.jpg" title={t('reportcard_title_car_buy2_0')} description={t('reportcard_description_car_buy2_0')} />
            <ReportCard readmore={true} link="stuff_1_1" src="../images/uploads/stuff_1_1.jpg" title={t('reportcard_title_stuff_1_1')} description={t('reportcard_description_stuff_1_1')} />
          


		</div>
		







	</div>
</section>




        </div>
    )
}

export default Reports;
