import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import FaqSpoiler from "./FaqSpoiler";

function Faq(){
    
    const [t] = useTranslation();

    const faq_dataset = [
        {title:t('faqtitle1'), description:t('faqdescr1')},
        {title:t('faqtitle2'), description:t('faqdescr2')},
        {title:t('faqtitle3'), description:t('faqdescr3')},
        {title:t('faqtitle4'), description:t('faqdescr4')},
        {title:t('faqtitle5'), description:t('faqdescr5')},
        {title:t('faqtitle6'), description:t('faqdescr6')},
        {title:t('faqtitle7'), description:t('faqdescr7')},
        {title:t('faqtitle8'), description:t('faqdescr8')},
        {title:t('faqtitle9'), description:t('faqdescr9')},
        {title:t('faqtitle10'), description:t('faqdescr10')}
    ]

    const faq_list = faq_dataset.map( (item, index) => {
        return <FaqSpoiler title={item.title} description={item.description} key={index} />
    });

    return(
        <div>
            <section className="main-section">
                <div className="container">		
                    <h2>Відповіді на часті запитання:</h2>				
                    { faq_list }		 
                </div>
            </section>
        </div>
    )
}

export default Faq;