 
import { Link } from "react-router-dom";

function ReportCard(props) {
    
   

    return (
       
            
            <div className="grid4 tb6 sm12">
				<div className="cardbox">
					<div className="cardbox-img" style={{backgroundImage: `url(${props.src})`}}></div>
					<div className="cardbox-content">
					<div className="cardbox-top">
						<div className="cardbox-title">{props.title}</div>
						<div className="cardbox-text">
							{props.description}
						</div>
					</div>					
					
                    {
                        props.readmore && <Link to={props.link} className="cardbox-more">Детальніше »</Link>                        
                    }
                    

					</div>
				</div>
				</div>

        
    )
}

export default ReportCard;