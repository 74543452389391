function FaqSpoiler(props) {
    
	function collapser(e) {
		
		
		e.preventDefault()
    let child = e.target.nextElementSibling
    let height = child.children[0].offsetHeight
    let parent = e.target.closest('.spoiler')
    if ( !parent.classList.contains('opened') ) {
      
      // collapse. if it is needed
      document.querySelectorAll('.spoiler').forEach(el => el.classList.remove('opened'))
      document.querySelectorAll('.spoiler-hide').forEach(el => el.removeAttribute('style'));
      // collapse. end

      parent.classList.add('opened')
      child.style.height = height + 'px'
    } else {
      parent.classList.remove('opened')
      child.removeAttribute('style')
    }

	}

	return (
        <div>

            <div className="spoiler">
			<a onClick={collapser} href="#" className="spoiler-name">{props.title}</a>
			<div className="spoiler-hide">
			  <div className="spoiler-hide__inner">
				<p>{props.description}</p>
			  </div>
			</div>
		  </div>

        </div>
    )
}

export default FaqSpoiler;