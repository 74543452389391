import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Swift() {

	const [t] = useTranslation();

    return (
        <div>

<section className="main-section">
	<div className="container">		
		
		<div className="article">
			<h2 dangerouslySetInnerHTML={{__html: t('SwiftLabel')}}></h2>
			<p><span dangerouslySetInnerHTML={{__html: t('SwiftDescriptionLeft')}}></span> <Link to="/contacts"><span dangerouslySetInnerHTML={{__html: t('SwiftDescriptionMiddle')}}></span></Link> <span dangerouslySetInnerHTML={{__html: t('SwiftDescriptionRight')}}></span></p>
		</div>

	</div>
</section>

        </div>
    )
}

export default Swift;