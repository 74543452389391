import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

function ReportsInternal() {

    const [t] = useTranslation();

    const data = {
        "stuff_40" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/vC2KEmgu2Kw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
        "stuff_39" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/jWuQoxjEbmQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
        "stuff_38" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/h0b4810m6LI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
        "stuff_37" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/1ZV6EE7V8oA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
        "stuff_36" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/x-m_4bijBqk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',

        "stuff_35" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/iLp_-YMDzfw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
        "stuff_34" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/G2aoNH_0SgQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
        "stuff_33" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/DN0bXlEScrQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
        "car_buy5_0" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/7EjoYlLM8Kw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',

        "stuff_32" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/iXhAjg24KK8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
        "stuff_31" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/CQwveJkeNlw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
        "stuff_30" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/RHt0xQCmaHg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
        "stuff_29" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/PbScWbollAM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
        "stuff_28" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/GdsnKHEBAnU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',

        "stuff_27" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/KaAJYpmQVo0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
        "stuff_26" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/tXrbrcLwCR0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
        "stuff_25" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/pLetsH3oPw4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
        "stuff_24" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/71LqrqW7PWI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
        "car_repair5" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/swqOhcP7-jk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
        "car_buy4_0" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/8UZw93t689U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
        
        "car_repair4" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/aUTonYj_Od8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',

        "stuff_23" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/5s1V1Pa-Mbw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
        "stuff_22" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/4cHbpHgLvIM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>',
        "stuff_21" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/KHJij_p2mS4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>',
        "stuff_20" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/p7ogcvUNNNw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>',
        "stuff_19" : '<div class="internal_reports_img"><img src="../images/uploads/stuff_19.jpg"></div><div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/60hmg6ympzw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div><div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/eqvL0gjC8O0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>',
        "stuff_18" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/jAeBWRlNwIA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>',
        "stuff_17" : '<div class="internal_reports_img"><img src="../images/uploads/stuff_17.jpg"></div>',
        "stuff_15" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/Id_--mR-cSA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>',
        "stuff_14" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/nurIa5NA-FI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>',
        "stuff_13" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/fms_6vyES-w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>',
        "car_repair3" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/x4AffSWqr48" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>',
        "stuff_12" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/Of7_Uj-c6Lw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>',
        "stuff_11" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/8HJVdgxYKLY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>',
        "stuff_10_1" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/oK69kEaB07c?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
        "stuff_9_2" : '<div class="internal_reports_img"><img src="../images/uploads/stuff_9_2.jpg"></div><div class="internal_reports_img"><img src="../images/uploads/stuff_9_1.jpg"></div>',
        "stuff_5_1" : '<div class="internal_reports_img"><img src="../images/uploads/stuff_5_1.jpg"></div><div class="internal_reports_img"><img src="../images/uploads/stuff_5_2.jpg"></div>',
        "car_repair2_1" : '<div class="internal_reports_img"><img src="../images/uploads/car_repair2_1.jpg"></div><div class="internal_reports_img"><img src="../images/uploads/car_repair2_2.jpg"></div><div class="internal_reports_img"><img src="../images/uploads/car_repair2_3.jpg"></div><div class="internal_reports_img"><img src="../images/uploads/car_repair2_4.jpg"></div><div class="internal_reports_img"><img src="../images/uploads/car_repair2_5.jpg"></div><div class="internal_reports_img"><img src="../images/uploads/car_repair2_6.jpg"></div>',
        "car_buy2_0" : '<div class="video-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/lkyo768wOVQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>',
        "stuff_1_1" : '<div class="internal_reports_img"><img src="../images/uploads/stuff_1_1.jpg"></div><div class="internal_reports_img"><img src="../images/uploads/stuff_1_2.jpg"></div>',
        "stuff_16" : '<div class="internal_reports_img"><img src="../images/uploads/stuff_16_1.jpg"></div><div class="internal_reports_img"><img src="../images/uploads/stuff_16_2.jpg"><img src="../images/uploads/stuff_16_3.jpg"><img src="../images/uploads/stuff_16_4.jpg"></div>',
        "boots_1" : '<div class="internal_reports_img"><img src="../images/uploads/boots_6.jpg"></div><div class="internal_reports_img"><img src="../images/uploads/boots_5.jpg"></div><div class="internal_reports_img"><img src="../images/uploads/boots_4.jpg"></div><div class="internal_reports_img"><img src="../images/uploads/boots_3.jpg"></div><div class="internal_reports_img"><img src="../images/uploads/boots_2.jpg"></div><div class="internal_reports_img"><img src="../images/uploads/boots_1.jpg"></div>',
        

    };

    

    const {pageId} = useParams();
    

    return (
        <section className="main-section">
            <div className="container">
                <div className="article">
                 <p><Link to="/reports" dangerouslySetInnerHTML={{__html: t('BackToReports')}}></Link></p>
                 <div dangerouslySetInnerHTML={{__html: data[pageId] }}  />
                </div>
            </div>
        </section>
    )
}

export default ReportsInternal;