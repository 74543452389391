import { useEffect, useState } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import opocketlogo from '../images/opocketlogo.svg';

import backgroundImage_index from '../images/pagebg1.jpg';
import backgroundImage_about from '../images/pagebg2.jpg';
import backgroundImage_loot from '../images/loot.jpg';
import backgroundImage_reports from '../images/reports.jpg';
import backgroundImage_contacts from '../images/contacts.jpg';
import backgroundImage_faq from '../images/pagebg3.jpg';
import { useTranslation } from 'react-i18next';
import useLocalStorage from '../hooks/useLocalStorage';
import i18n from '../i18n';

function Header() {

	

	const {t} = useTranslation();
	const [language, setLanguage] = useLocalStorage('language', 'ua');


	if ( localStorage.getItem("language") == null) {
		i18n.changeLanguage('ua');
		setLanguage('ua');	
	}

    const location = useLocation();
    const [backgroundImage, setBackgroundImage] = useState(backgroundImage_index);
	const [title, setTitle] = useState('Головна');
	
	const internalReports = matchPath("/reports/:pageId", location.pathname);
	
    useEffect( ()=>{
        
		let rootPage = location.pathname.split("/").pop();
         
		switch(rootPage) {
            case "":
            	setTitle( t('Home') );    
				setBackgroundImage(backgroundImage_index);
            break;
            case "about":
				setTitle(t('About'));
                setBackgroundImage(backgroundImage_about);
            break;
            case "loot":
				setTitle(t('Loot'));
                setBackgroundImage(backgroundImage_loot);
            break;
            case "reports":
				setTitle(t('Reports'));
                setBackgroundImage(backgroundImage_reports);
            break;
            case "support":
				setTitle(t('Support'));
                setBackgroundImage(backgroundImage_index);
            break;
			case "contacts":
				setTitle(t('Contacts'));
                setBackgroundImage(backgroundImage_contacts);
            break;
			case "swift":
				setTitle(t('Swift'));
                setBackgroundImage(backgroundImage_index);
            break;
			case "faq":
				setTitle(t('Faq'));
                setBackgroundImage(backgroundImage_faq);
            break;			 
        }

		if ( internalReports ) {
			setTitle(t('Reports'));
			setBackgroundImage(backgroundImage_reports);
		}

		  
		if ( localStorage.getItem("language") ) {
			setupGraphicallyLanguage( JSON.parse(localStorage.getItem("language")) );
		}

    }, [location] );

	function setLanguageList(params) {
		
		setupGraphicallyLanguage(params);
		
		i18n.changeLanguage(params);
		setLanguage(params);	
		
		
		document.querySelector('.language').classList.remove('opened');

		document.querySelector('.nav-overlay').addEventListener('click', () => {
			document.body.classList.remove('nav-opened');
		});

		window.location.reload();
	}
	
	function setupGraphicallyLanguage(lang){
		document.querySelector('.language-btn').classList.add("language-" + lang);
		document.querySelector('.language-btn').innerText = lang.toUpperCase();
	}

	function showLanguageSelector(e) {		
			let parent = e.target.parentElement
			if ( !parent.classList.contains('opened') ) {
			  parent.classList.add('opened');
			} else {
			  parent.classList.remove('opened');
			}		
	}

	function toggleMobileMenu(e) {
		e.preventDefault()
		if ( !document.body.classList.contains('nav-opened') ) {
			document.body.classList.add('nav-opened')
		} else {
			document.body.classList.remove('nav-opened')
		}
	}

    return (
        
        <div>
            <header className="header" style={{ backgroundImage:`url(${backgroundImage})` }}>
    	<div className="header-shadow"></div>
    	<div className="header-shape">
    		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
    			<path className="elementor-shape-fill" d="M500.2,94.7L0,0v100h1000V0L500.2,94.7z"></path>
    		</svg>
    	</div>
    	<div className="header-top">
    		<div className="container">
    		
    			<div className="header-logo">
    				<a href="/" rel="noreferrer"><img src={opocketlogo} alt=""/></a>
    			</div>
    
    			<div className="header-column">
    				<div className="header-column__top">
    					<a href="https://www.instagram.com/a85avua/" target="_blank" rel="noreferrer" className="instabtn"></a>
    					<Link to="/support" className="btn btn-border sm-hidden">{t('Support')}</Link>
    					<div className="language">
    						<div onClick={showLanguageSelector} className="cursor-pointer language-item language-ua language-btn">UKR</div>
    						<ul className="language-dropdown">
    							<li className="cursor-pointer" onClick={()=>{setLanguageList("ua")}}><div className="language-item language-item-active language-ua">Українська</div></li>
    							<li className="cursor-pointer" onClick={()=>{setLanguageList("en")}}><div className="language-item language-item-active language-en">English</div></li>
    							<li className="cursor-pointer" onClick={()=>{setLanguageList("ru")}}><div className="language-item language-item-active language-ru">Русский</div></li>
    							{/*<li className="cursor-pointer" ><div className="lang-soon language-item language-item-active language-pl">Wkrótce</div></li>
    							<li className="cursor-pointer" ><div className="lang-soon language-item language-item-active language-de">Demnächst</div></li>*/}
    						</ul>
    					</div>
    					<div onClick={toggleMobileMenu} className="cursor-pointer btn-menu"></div>
    				</div>
    				<nav className="navmenu">
    					<ul>
    						<li><Link onClick={document.body.classList.remove('nav-opened')} className={ location.pathname === "/" ? "menu_header_current_page" : undefined } to="/">{t('Home')}</Link></li>
    						<li><Link onClick={document.body.classList.remove('nav-opened')} className={ location.pathname === "/about" ? "menu_header_current_page" : undefined } to="/about">{t('About')}</Link></li>
                            {/*<li><Link onClick={document.body.classList.remove('nav-opened')} className={ location.pathname === "/loot" ? "menu_header_current_page" : undefined } to="/loot">{t('Loot')}</Link></li>*/}
                            <li><Link onClick={document.body.classList.remove('nav-opened')} className={ location.pathname === "/reports" ? "menu_header_current_page" : undefined } to="/reports">{t('Reports')}</Link></li>
							<li><Link onClick={document.body.classList.remove('nav-opened')} className={ location.pathname === "/faq" ? "menu_header_current_page" : undefined } to="/faq">{t('Faq')}</Link></li>
    						<li><Link onClick={document.body.classList.remove('nav-opened')} className={ location.pathname === "/contacts" ? "menu_header_current_page" : undefined } to="/contacts">{t('Contacts')}</Link></li>
    					</ul>
    				</nav>
    				<div className="nav-overlay"></div>
    			</div>
    			
    		</div>
    		<Link to="/support" className="btn btn-border sm-visible">{t('Support')}</Link>
    	</div>
    
    	<div className="header-title">
    		<div className="container">
    			<h1>{title}</h1>
    		</div>
    	</div>
    </header>

		 
        </div>

    )
}

export default Header;