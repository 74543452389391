import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

 

function Contacts() {

    const [t] = useTranslation();

    const [formIsSubbmited, setFormIsSubbmited] = useState(false);
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userMessage, setUserMessage] = useState("");
    
    function formatName(e) {
        setUserName(e.target.value);
    }

    function formatEmail(e){
        setUserEmail(e.target.value);
    }

    function formatMessage(e){
        setUserMessage(e.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        
        
        
        let formData = new FormData();
        formData.append('name', userName);
        formData.append('email', userEmail);
        formData.append('message', userMessage);
        

        fetch("/", { method: "POST", headers: { "Content-Type": "application/x-www-form-urlencoded" }, body: new URLSearchParams(formData).toString() })
        .then(() => {
            setFormIsSubbmited(true);
        })
        .catch((error) => alert(error));
		
    };
	
    return (
        <div>
            <section className="main-section">
                <div className="container">
                    
                    <h2 dangerouslySetInnerHTML={{__html: t('ContactsPage')}}></h2>

                    <div className="article">			
                        <p dangerouslySetInnerHTML={{__html: t('ContactsPageDescription')}}></p>
                    </div>
                </div>
            </section>
        </div>
    )
		   
    /*
    return (
        <div>

            <section className="main-section">
                <div className="container">
                    
                    <h2>Форма зворотнього зв'язку:</h2>		
                    
                    <div className="article">			
                        <p>Ви можете надіслати нам листа на пошту <a href="mailto:info@o-pocket.org?subject=form">info@o-pocket.org</a> чи скористуватися формою нижче.</p>
                    </div>
                     
                     { !formIsSubbmited ? (

                    <div>
                        <form name="ContactsPage" method="POST" data-netlify="true">
                        <div className="form-line">
                            <input type="text" className="form-control" name="name" onChange={formatName} placeholder="Ваше ім'я" />
                        </div>
                        <div className="form-line">
                            <input type="email" className="form-control" name="email" onChange={formatEmail} placeholder="Ваш E-Mail" />
                        </div>
                        <div className="form-line">
                            <textarea className="form-control" name="message" onChange={formatMessage} placeholder="Введить інформацию"></textarea>
                        </div>
                        <button className="btn btn-sm-block" type="submit" onClick={handleSubmit}>Відправити</button>
                        </form>
                    </div>
                    
                     ):( 
                    
                    <div className="article formbox_complete">
                        <hr />
                        <p>Форма відправлена!</p>
                    </div>

                     )}

                </div>
            </section>

        </div>
    )
    */
}

export default Contacts;