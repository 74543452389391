import ua from './trans/ua.json';
import ru from './trans/ru.json';
import en from './trans/en.json';

import { initReactI18next } from 'react-i18next';
import i18n from 'i18next'

const resources = {
    ua : {
        translation: ua
    },
    en : {
        translation: en
    },
    ru : {
        translation: ru
    }
}

i18n.use(initReactI18next).init(
    {
        resources,
        lng: JSON.parse(localStorage.getItem('language')) == null ? "ua" : JSON.parse(localStorage.getItem('language')),
        fallback: 'en',        
        defaultLanguage: 'ua'
})

export default i18n;