import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Support from "./Support";

function SupportPage(){

    const [t] = useTranslation();

    return (
        <div>
            <section className="main-section">
                <div className="container">

                <div className="article">
                    <h2>{t('SupportEasy')}</h2>
                    <p><span dangerouslySetInnerHTML={{__html: t('SupportEasyGiftLeft')}}></span><Link to="/contacts"><span dangerouslySetInnerHTML={{__html: t('SupportEasyGiftMiddle')}}></span></Link> <span dangerouslySetInnerHTML={{__html: t('SupportEasyGiftRight')}}></span></p>
                </div>

                <Support />

                </div>
            </section>
        </div>
    
    )
}

export default SupportPage;