import monobank from '../images/monobank.png';
import btc from '../images/btc.svg';
import usdt from '../images/usdt.svg';
import eth from '../images/eth.svg';
import paypal from '../images/paypal-mark-monotone-transparent.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Support() {

	const [t] = useTranslation();

    function selectOnClick(event) {
        event.target.select();
    }

    return(
        <div>
            <div className="row">
			<div className="grid4 sm12 article">
				<h3>Monobank:</h3>
				<p>
					<a href="https://send.monobank.ua/jar/9Lhpgjujri?t=З%20сайту" target="_blank" rel="noreferrer noopener" className="btn btn-sm-block monobank">
						<img src={monobank} alt="Monobank" width="19" height="19"/> <span>{t('DonateLabel')}</span>
					</a>
				</p>				
				<p><b>{t('DonateCard')}:</b> 5375 4112 0217 8054<br/>({t('DonateCardInfo')})</p>
			</div>
			<div className="grid4 sm12 article">
				<h3>PayPal:</h3>				 
				 <p>
					<a href="https://www.paypal.com/donate/?business=VWXLYJQY692YC&no_recurring=0&item_name=Donate+for+https%3A%2F%2Fo-pocket.org&currency_code=USD" target="_blank" rel="noreferrer noopener" className="btn btn-sm-block monobank paypal">
						<img src={paypal} width="19" height="19" alt="PayPal" /> <span>{t('DonateLabel')}</span>
					</a>
				</p>
				<p><b>{t('DonateAccountPayPal')}:</b> info@o-pocket.org</p>				
			</div>

			<div className="grid4 sm12 article">
				<h3>{t('Cryptolabel')}:</h3>
				<div className="cryptoline"><p><img src={btc} width="19" height="19" alt="BTC" /> <strong>BTC (Bitcoin)</strong></p> <input type="text" onClick={selectOnClick} readOnly value="bc1q8w6rkq5nzskjlv4usz5h8ewp5tl0fvzkwchjp6" /></div>				
				<div className="cryptoline"><p><img src={usdt} width="19" height="19" alt="USDT" /> <strong>USDT (TRC20 TRX), <span className="crypto-min">{t('CryptoMinimal')} 3 USDT</span></strong></p> <input type="text" onClick={selectOnClick} readOnly value="TVDGZVuwaqBUHeZNdbNWPrM6NK63VrwMji" /></div>				
				<div className="cryptoline"><p><img src={eth} width="19" height="19" alt="ETH" /> <strong>ETH ERC20(Ethereum), <span className="crypto-min">{t('CryptoMinimal')} 0.003 ETH</span></strong></p> <input type="text" onClick={selectOnClick} readOnly value="0x9467A94B538d8B662026F63D73eAAE2cEE02D76E" /></div>
			</div>
		</div>

		<div className="article">
			<h3>{t('DonateHowThisWorks')}</h3>
				<p>
					<span dangerouslySetInnerHTML={{__html: t('DonateHowThisWorksString1')}}></span> <Link to="/swift"><span dangerouslySetInnerHTML={{__html: t('DonateHowThisWorksString2')}}></span></Link>.
				</p>
		</div>
        </div>
    )

}

export default Support;