function Loot() {
    return (
        <div>

            <section className="main-section">
                <div className="container">		
                    <div className="article">			 
                        <h2>Що можна отримати за донат:</h2>
                        <p>Сторінка ще в розробці. Це потребує часу.</p>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Loot;