import Header from "./Components/Header";
import About from "./Components/About";
import Main from "./Components/Main";
import Loot from "./Components/Loot";
import Reports from "./Components/Reports";
import SupportPage from "./Components/SupportPage";
import Contacts from "./Components/Contacts";
import Swift from "./Components/Swift";

import Footer from "./Components/Footer";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Faq from "./Components/Faq";
import ReportsInternal from "./Components/ReportsInternal";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
          
          <Header />      
          
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/about" element={<About />} />
              <Route path="/loot" element={<Loot />} />
              <Route path="/reports" element={<Reports />} />              
              <Route path="/reports/:pageId" element={<ReportsInternal />} />
              
              <Route path="/donate" element={<SupportPage />} />
              <Route path="/support" element={<SupportPage />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/swift" element={<Swift />} />
              
            </Routes>
          
          <Footer />
          
           
      </BrowserRouter>
    </div>
  );
}

export default App;
