import { useTranslation } from "react-i18next";

function About(){

	const [t] = useTranslation();
    
    return(
        <div>
            <section className="main-section">
	<div className="container">
		
		<div className="article">
			 
			<h2>{t('AboutLabel')}</h2>

			<p dangerouslySetInnerHTML={{__html: t('AboutDescription')}}></p>
			
			<div class="video-container">
				<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/B7ni-I9VKnk?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
			</div>
			 
		</div>


	</div>
</section>
        </div>
    )
}

export default About;